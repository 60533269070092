import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { theme } from '../theme';
import Layout from '../components/Layout';
import Box from '../reusecore/src/elements/Box';
import Input from '../components/Input';
import { Formik } from 'formik';
import Button from '../reusecore/src/elements/Button/button';
import firebase from 'firebase';
import * as Yup from 'yup';
import Text from '../reusecore/src/elements/Text/text';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from '../reusecore/src/elements/Alert';

export interface ForgotPasswordPageProps {}

export interface ForgotPasswordValues {
  email: string;
}

const forgotPasswordValidationSchema = Yup.object().shape<ForgotPasswordValues>(
  {
    email: Yup.string()
      .email('Please provide a valid email address.')
      .required('Please fill out this section.'),
  }
);

const ForgotPasswordPage: React.FunctionComponent<ForgotPasswordPageProps> = (): JSX.Element => {
  const [emailSentTo, setEmailSentTo] = useState<string>(null);

  return (
    <Layout contrast>
      <Container fluid style={{ backgroundColor: theme.colors.sand }}>
        <Box
          flexBox
          height="100vh"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          {!!emailSentTo ? (
            <Box
              flexBox
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              width={11}
            >
              <Text
                fontFamily="heading"
                fontWeight={3}
                fontSize={5}
                pt={3}
                pb={4}
                lineHeight="normal"
                m={0}
              >
                Reset Password
              </Text>
              <FontAwesomeIcon icon={faPaperPlane} size="3x" />
              <Text textAlign="center" pt={3}>
                Thanks, if your email address matches a Helsa account you will
                receive an email on how to reset your password.
              </Text>
            </Box>
          ) : (
            <Box flexBox flexDirection="column" width={11}>
              <Formik
                initialValues={{
                  email: '',
                }}
                validationSchema={forgotPasswordValidationSchema}
                onSubmit={(values, formikBag) => {
                  firebase
                    .auth()
                    .sendPasswordResetEmail(values.email)
                    .then(() => {
                      setEmailSentTo(values.email);
                    })
                    .catch(() => {
                      setEmailSentTo(values.email);
                      formikBag.setSubmitting(false);
                    });
                }}
                validateOnBlur={true}
              >
                {({
                  errors,
                  touched,
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => {
                  return (
                    <>
                      <Text
                        fontFamily="heading"
                        fontWeight={3}
                        fontSize={5}
                        pt={3}
                        pb={4}
                        lineHeight="normal"
                        m={0}
                        textAlign="center"
                      >
                        Reset Password
                      </Text>
                      <Input
                        label="Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email ? (
                        <Alert
                          isMaterial={true}
                          className="warning"
                          colors="error"
                        >
                          <Text>{errors.email}</Text>
                        </Alert>
                      ) : null}
                      <Box flexBox justifyContent="center">
                        <Button
                          px={4}
                          mt={4}
                          className="default"
                          title="NEXT"
                          onClick={handleSubmit}
                          disabled={isSubmitting}
                        />
                      </Box>
                    </>
                  );
                }}
              </Formik>
            </Box>
          )}
        </Box>
      </Container>
    </Layout>
  );
};

export default ForgotPasswordPage;
